import {
  FaChartBar,
  FaCog,
  FaFile,
  FaHeadphones,
  FaHome,
  // FaQuestionCircle,
  FaUser,
} from "react-icons/fa";
import { AiFillCheckSquare } from "react-icons/ai";

export const upperData = [
  {
    id: 1,
    title: "ड्यासबोर्ड",
    title_en: "Dashboard",
    icon: <FaHome />,
    path: "/",
  },
  {
    id: 2,
    title: "जारी भएका सिफारिस",
    title_en: "Sifarish",
    icon: <FaFile />,
    path: "/papers/verified/1",

    rule: "view:jariVayekoSifaris",
  },
  {
    id: 3,
    title: "सिफारिस अनुरोध प्रमाणिकरण",
    title_en: "Sifarish requests",
    icon: <FaUser />,
    badge: true,
    path: "/verify/1",
    rule: "view:sifarisAnurodPranali",
  },
];

export const lowerData = [
  // {
  //   id: 4,
  //   title: "जारी भएका सिफारिस",
  //   icon: <FaFile />,
  //   path: false,
  //   iconClosed: <FaChevronDown />,
  //   iconOpened: <FaChevronUp />,
  //   subNav: [
  //     {
  //       title: "सबै जारी भएका सिफारिस",
  //       path: "/papers/verified/1",
  //     },
  //     {
  //       title: "सबै अप्रमाणित सिफारिस",
  //       path: "#",
  //       // path: "/papers/unverified",
  //     },
  //   ],
  // },
  {
    id: 6,
    title: "सेटिंग्स",
    title_en: "Settings",
    icon: <FaCog />,
    path: "/settings",
    rule: "view:settings",
  },
  {
    id: 7,
    title: "रिपोर्ट",
    title_en: "Report",
    icon: <FaChartBar />,
    path: "/reports",
    rule: "view:reports",
  },
  {
    id: 11,
    title: "सिफारिस  प्रमाणिकरण",
    title_en: "Sifarish Verification",
    icon: <AiFillCheckSquare />,
    path: "/sifarish/verification/",
    rule: "view:sifarishVerification",
  },
  // {
  // 	id: 9,
  // 	title: 'FAQ',
  // 	icon: <FaQuestionCircle />,
  // 	path: '/faq',
  // 	rule: 'view:faq',
  // },
  {
    id: 10,
    title: "सपोर्ट",
    title_en: "Support",
    icon: <FaHeadphones />,
    path: "/support",
    rule: "view:support",
  },
];
