export const palikaFeatures = {
  bansgadhimun: [
    "EN_MID", //'-2.3cm'
  ],
  siyarimun: ["NEPAL_LOGO_WATERMARK", "FONT_KALIMATI", "EN_FAR"],
  mathagadhimun: ["EMAIL_PASSWORD", "EN_NEAR", "HIGH_RIGHT_MARGIN"],
  nepalgunjmun: ["ENGLISH_HEADER_FOR_ENGLISH_TEMPLATE", "EN_NEAR"],
  ghodaghodimun: ["EN_MID"],
  rajapurmun: ["EN_MID"],
  dullumun: ["EN_NEAR"],
  bherigangamun: ["EN_MID"],
  kabilashi: ["EN_MID"],
  chandannathmun: ["EMAIL_PASSWORD", "EN_MID"],
};

const FeatureVals = {
  EN_NEAR: "-1.8cm",
  EN_MID: "-2.3cm",
  EN_FAR: "-3.2cm",
};

export const hasFeature = (palika, feature) => {
  return (palikaFeatures[palika] ?? []).includes(feature);
};

export const getFeatureVal = (palika, feature_SUB) => {
  const featureFlag = palikaFeatures[palika]?.find((f) =>
    f.includes(feature_SUB)
  );
  return hasFeature(palika, featureFlag) ? FeatureVals[featureFlag] : "";
};
