import { Helmet } from "react-helmet";
import { lazy, Suspense, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

//
import { PrivateRoute } from "./auth-route";

//
import Layout from "./layout/Layout";
import Loader from "./_elements/Loader";

//
import { clearUiMessage } from "./_redux/slices/ui";
import { getUserData } from "./_redux/slices/users";
import { fetchNagarpalika } from "./_redux/slices/nagarpalika";
import { fetchActiveFiscalYear } from "./_redux/slices/fiscalyear";

//
import "./sass/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "./components/Form/index.css";
import SabikKoPalika from "./pages/Settings/SabikKoPalika";
import { hasFeature } from "./featureConfig";

// auth
const Login = lazy(() => import("./pages/auth/Login"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

// templates
const Templates = lazy(() => import("./pages/Templates/Templates"));
const PaperSingle = lazy(() => import("./pages/Templates/PaperSingle"));
const TemplateOld = lazy(() => import("./pages/Templates/TemplateOld"));
const TemplateForm = lazy(() => import("./pages/Templates/TemplateForm"));
const TemplateSingle = lazy(() => import("./pages/Templates/TemplateSingle"));

const Faq = lazy(() => import("./pages/Faq"));
const Report = lazy(() => import("./pages/Report"));
const Support = lazy(() => import("./pages/Support"));
const Dashboard = lazy(() => import("./pages/Dashboard"));

// Settings
const Ward = lazy(() => import("./pages/Settings/Ward/Ward"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const AddWard = lazy(() => import("./pages/Settings/Ward/AddWard"));
const FiscalYear = lazy(() => import("./pages/Settings/FiscalYear"));
const Profile = lazy(() => import("./pages/Settings/Profile/Profile"));
const AllUsers = lazy(() => import("./pages/Settings/Users/AllUsers"));
const WardUser = lazy(() => import("./pages/Settings/Users/WardUser"));
const SifarishType = lazy(() => import("./pages/Settings/SifarishType"));
const MayorTab = lazy(() => import("./pages/Settings/MayorTab/MayorTab"));
const DesignTempate = lazy(() => import("./pages/Settings/DesignTemplate"));
const Applicant = lazy(() => import("./pages/Settings/Applicant/Applicant"));
const DocumentsType = lazy(() =>
  import("./pages/Settings/Documents/DocumentsType")
);
const FormerMunicipal = lazy(() =>
  import("./pages/Settings/FormerMunicipal/FormerMunicipal")
);

const NagarpalikaDetails = lazy(() =>
  import("./pages/NagarpalikaDetails/NagarpalikaDetails")
);
const SifarishVerification = lazy(() =>
  import("./pages/SifarishVerification/SifarishVerification")
);

// citizens
const CitizensHome = lazy(() => import("./pages/Citizens/CitizensHome"));
const CitizensTemplateListing = lazy(() =>
  import("./pages/Citizens/CitizensTemplateListing")
);
const SifarishStatus = lazy(() => import("./pages/Citizens/SifarishStatus"));
const CitizensTemplate = lazy(() =>
  import("./pages/Citizens/CitizensTemplate")
);
const VerifyCitizenTemplate = lazy(() =>
  import("./pages/VerifyCitizenTemplate")
);
const CitizenTemplateSingle = lazy(() =>
  import("./pages/CitizenTemplateSingle")
);
const CitizensFormSubmitted = lazy(() =>
  import("./pages/Citizens/CitizensFormSubmitted")
);

// papers
const Verified = lazy(() => import("./pages/Papers/Verified"));
const Unverified = lazy(() => import("./pages/Papers/Unverified"));

//
const Users = lazy(() => import("./pages/Users/Users"));

/**
 *
 */
function App({
  clearUiMessage,
  fetchNagarpalika,
  getUserData,
  fetchActiveFiscalYear,
}) {
  const palika = useSelector((state) => state.ui.palika);
  const message = useSelector((state) => state.ui.message);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const nagarpalikaInfo = useSelector(
    (state) => state.nagarpalika?.nagarpalika
  );

  const fontClass = hasFeature(palika, "FONT_KALIMATI") ? "kalimati" : "mukta";
  useEffect(() => {
    fetchNagarpalika();
  }, [fetchNagarpalika]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserData();
      localStorage.removeItem("showLoginPage");
      fetchActiveFiscalYear();
    }
  }, [getUserData, fetchActiveFiscalYear, isLoggedIn]);

  useEffect(() => {
    if (message) {
      if (message.error === false) {
        toast.success(message.message);
        clearUiMessage();
      } else if (message) {
        toast.error(message.message);
        clearUiMessage();
      } else {
        toast.success(message);
      }
    }
  }, [message, clearUiMessage]);

  return (
    <div className={`${fontClass}`}>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            {`${
              nagarpalikaInfo?.name_np ? `${nagarpalikaInfo?.name_np} | ` : ""
            } सिफारिस प्रणाली`}
          </title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />

            <Route exact path="/signup" component={SignUp} />

            <Route exact path="/citizens" component={CitizensHome} />
            <Route
              exact
              path="/citizens/templates"
              component={CitizensTemplateListing}
            />
            <Route exact path="/status" component={SifarishStatus} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/password/reset" component={ResetPassword} />

            <Route
              exact
              path="/citizen-template/paper-submitted/"
              component={CitizensFormSubmitted}
            />
            <Route
              exact
              path="/citizen-template/:id"
              component={CitizensTemplate}
            />

            <Route
              exact
              path="/public/sifarish/verification"
              component={SifarishVerification}
            />
            <Route
              path="/"
              render={({ match }) => <RootRoutes basePath={match.path} />}
            />
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </Suspense>
    </div>
  );
}

export default connect(null, {
  clearUiMessage,
  getUserData,
  fetchNagarpalika,
  fetchActiveFiscalYear,
})(App);

const RootRoutes = ({ basePath }) => (
  <Layout>
    <PrivateRoute
      exact
      path="/"
      component={Dashboard}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/reports"
      component={Report}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings"
      component={Settings}
      accessGrantedRoles={["super_admin", "ito_admin", "ward_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/fiscalyear"
      component={FiscalYear}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/designtemplate"
      component={DesignTempate}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/ward"
      component={Ward}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/allusers/:pageNum"
      component={AllUsers}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/warduser/:id"
      component={WardUser}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/profile"
      component={Profile}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sifarishtype"
      component={SifarishType}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sabikKoPalika/:pageNum"
      component={SabikKoPalika}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />

    <PrivateRoute
      exact
      path="/settings/documentstype/:pageNum"
      component={DocumentsType}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/mayor"
      component={MayorTab}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/formermunicipal"
      component={FormerMunicipal}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/applicants"
      component={Applicant}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/faq"
      component={Faq}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/support"
      component={Support}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/ward/create/"
      component={AddWard}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/ward/update/:id"
      component={AddWard}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/nagarpalika/:pageNum"
      component={NagarpalikaDetails}
      accessGrantedRoles={["super_admin", "ito_admin"]}
    />
    <Route
      exact
      path="/sifarish/verification"
      component={SifarishVerification}
    />
    <PrivateRoute
      exact
      path="/users"
      component={Users}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/papers/unverified"
      component={Unverified}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/verify/:pageNum"
      component={VerifyCitizenTemplate}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/citizen/template/:id"
      component={CitizenTemplateSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/papers/verified/:pageNum"
      component={Verified}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/template-single/:id"
      component={TemplateForm}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-single"
      component={TemplateSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/paper-single/:paperId"
      component={PaperSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/paper-single/:paperId/:citizen"
      component={PaperSingle}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/templates/:pageNum"
      component={Templates}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-old"
      component={TemplateOld}
      accessGrantedRoles={[
        "super_admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    {/* <Route
      path="*"
    >
      <NotFound />
    </Route> */}
  </Layout>
);
