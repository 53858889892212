import React, { useEffect, useState } from "react";

// components
import { DropDownLink, SidebarLink } from "./SubMenu";

// icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoCopy } from "react-icons/io5";
import { VscLoading } from "react-icons/vsc";
import { fetchTempCategory } from "../../_redux/slices/template";
import { connect, useSelector } from "react-redux";

function PaperTemplateRenderer({ fetchTempCategory }) {
  const [isLoading, setIsLoading] = useState(false);
  const templatesCategory = useSelector(
    (state) => state?.templateCategories?.templatesCategory
  );
  const [subNav, setSubNav] = useState("");

  useEffect(() => {
    const getSidebarData = async () => {
      try {
        setIsLoading(true);
        fetchTempCategory();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    getSidebarData();
  }, [fetchTempCategory]);

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center ">
          <VscLoading className="spin" />
        </div>
      ) : (
        templatesCategory?.map((category) => {
          return (
            <React.Fragment key={category.id}>
              <SidebarLink
                to={"#"}
                onClick={() => {
                  if (category.title === subNav) {
                    setSubNav("");
                  } else {
                    setSubNav(category.title);
                  }
                }}
              >
                <div className="menu-items">
                  {/* <img src={category.icon} alt={category.title} /> */}
                  <IoCopy />
                  <span>{category.title}</span>
                </div>
                <div className="chevron">
                  {subNav === category.title ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
              </SidebarLink>
              {subNav === category.title ? (
                <div>
                  {category.templates.map((template) => {
                    return (
                      <DropDownLink
                        to={`/template-single/${template.id}`}
                        key={template.id}
                        activeClassName="navbar__link--active"
                        className="templateSubNav"
                        exact={true}
                      >
                        {template.title}
                      </DropDownLink>
                    );
                  })}
                </div>
              ) : null}
            </React.Fragment>
          );
        })
      )}
    </div>
  );
}

export default connect(null, { fetchTempCategory })(PaperTemplateRenderer);
