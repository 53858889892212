/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeLanguage } from '../_redux/slices/languages';

//
import { FaRegKeyboard } from 'react-icons/fa';

/**
 *
 */
export default function LanguageSelector({ color = 'white' }) {
	const dispatch = useDispatch();
	const language = useSelector((state) => state.language.language);

	const handleToggleLanguage = useCallback(() => {
		if (language === 'english') {
			dispatch(handleChangeLanguage({ language: 'nepali' }));
		} else {
			dispatch(handleChangeLanguage({ language: 'english' }));
		}
	}, [dispatch, language]);

	useEffect(() => {
		const handleLanguageChangeShortcut = (event) => {
			if (event.keyCode === 76 && event.ctrlKey) {
				event.preventDefault();
				handleToggleLanguage();
			}
		};
		document.addEventListener('keydown', handleLanguageChangeShortcut);

		return () => document.removeEventListener('keydown', handleLanguageChangeShortcut);
	}, [handleToggleLanguage]);

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{language === 'nepali' ? 'इनपुट भाषा परिवर्तन गर्न क्लिक गर्नुहोस्।' : 'Click to change input language'}
		</Tooltip>
	);
	return (
		<OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
			<div
				css={css`
					border: 1px solid ${color};
					color: ${color};
					border-radius: 4px;
					padding: 4px 8px;
					cursor: pointer;
					transition: color 200ms, background-color 200ms;
					:hover {
						background-color: white;
						color: #6677ef;
					}
				`}
				onClick={handleToggleLanguage}
			>
				<span className="d-flex justify-content-center align-items-center font-weight-bold">
					<FaRegKeyboard fontSize={18} className="mr-2" /> {language === 'nepali' ? 'NE' : 'EN'}
				</span>
			</div>
		</OverlayTrigger>
	);
}
