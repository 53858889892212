/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import SabikKoPalikaForm from "./SabikKoPalikaForm";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";

const SabikKoPalika = ({
    count,
}) => {

    const [showAdd, setShowAdd] = useState(false);
    const [palikaDetail, setPalikaDetail] = useState(null);
    const [sabikKoPalika, setSabikKoPalika] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);


    const fetchSabikKoPalika = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchWrapper.get('/former-wards/');
            return setSabikKoPalika(response.data.results)
        }
        catch (err) {
            console.log(err, 'er')
        }
        finally {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        fetchSabikKoPalika();
    }, [fetchSabikKoPalika]);

    const handleEdit = (palika) => {
        setPalikaDetail(palika);
        setShowAdd(true);
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await fetchWrapper.delete(`/former-wards/${show?.id}/`);
            toast.success('पालिकाको प्रकार सफलतापूर्वक हटाइयो।');
            fetchSabikKoPalika();
            setShow(false);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            {showAdd && (
                <Row className="mt-2">
                    <Col md={12}>
                        <SabikKoPalikaForm
                            showAdd={showAdd}
                            setShowAdd={setShowAdd}
                            palika={palikaDetail}
                            callback={fetchSabikKoPalika}
                        />
                    </Col>
                </Row>
            )}
            <Row className="mt-2">
                <Col md={12}>
                    <Card>
                        <Card.Header className="card-header-custom">
                            <h2>साविकको पालिका</h2>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setShowAdd(true);
                                    setPalikaDetail(null);
                                }}
                            >
                                <FaPlusCircle />
                                &nbsp; नयाँ साविकको पालिका
                            </Button>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <Table striped hover responsive>
                                    <thead>
                                        <tr className="sabika-tr">
                                            <th>शीर्षक</th>
                                            <th>Title</th>
                                            <th>कार्यहरू</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan={2} className="w-100 d-flex ">
                                                    <VscLoading className="spin" />
                                                </td>
                                            </tr>
                                        ) : sabikKoPalika?.length > 0 ? (
                                            sabikKoPalika.map((palika, index) => (
                                                <tr key={palika.id} className="sabika-tr">
                                                    <td>{palika.title}</td>
                                                    <td>{palika.title_en}</td>
                                                    <td>
                                                        <Button
                                                            variant="info"
                                                            className="mr-1"
                                                            onClick={() => handleEdit(palika)}
                                                        >
                                                            <FaEdit />
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => setShow(palika)}
                                                        >
                                                            <FaTrash />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    <h3 className="color-primary">साविकको पालिका सिर्जना गरिएको छैन।</h3>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={Boolean(show)} onHide={() => setShow(false)} centered>
                <Modal.Header>
                    <h3>साविकको पालिका</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className="h5 font-weight-lighter">
                        {" "}
                        के तपाइँ यो प्रकार हटाउन चाहानुहुन्छ?{" "}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger" onClick={handleDelete} disabled={loading}>
                        {
                            loading ? <VscLoading className="spin" /> : "सुनिश्चित गर्नुहोस्"
                        }

                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};


export default SabikKoPalika;
