import NepaliDate from "nepali-date-converter";
import { nepaliNumbers } from "../_datas";
import moment from "moment";
import { NEPAL_SAMBAT } from "../_datas/nepal_sambat";

export function nepaliNumberConverter(numString) {
  let nepaliNumber = "";
  for (let i = 0; i < numString?.length; i++) {
    if (nepaliNumbers.includes(numString[i])) {
      nepaliNumber += numString[i];
    } else if (nepaliNumbers[numString[i]]) {
      nepaliNumber += nepaliNumbers[numString[i]];
    } else {
      nepaliNumber += numString[i];
    }
  }
  return nepaliNumber;
}

export function getTodayMonth() {
  let todayMonth = new NepaliDate().format("M");
  todayMonth = String(todayMonth);
  if (todayMonth.length < 2) {
    return "0" + todayMonth;
  } else {
    return todayMonth;
  }
}

export function getTodayDay() {
  let todayDay = new NepaliDate().format("D");
  todayDay = String(todayDay);
  if (todayDay.length < 2) {
    return "0" + todayDay;
  } else {
    return todayDay;
  }
}

export const nepaliNumber = (number) => {
  const strNum = String(number);
  let strNepNum = "";
  for (let i = 0; i < strNum.length; i++) {
    strNepNum +=
      nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
      (nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
      "";
  }
  return strNepNum;
};

const generateUneditable = (data, key = "") =>
  `<span class="nonEditable" id="${key}">${data ?? ""}</span>`;

const generateNameRelationBox = (data, config) => {
  const noOfRow = Math.ceil(data.length / 4);
  const html = [];
  for (let index = 0; index < noOfRow; index++) {
    html.push(
      `<tr>${data
        .map(
          (data, idx) =>
            index === Math.floor(idx / 4) &&
            `<td align='center'><div class="text-container"><button type="button" class="text-container-close close" aria-label="Close" onclick="this.parentNode.parentNode.remove()">
			<span aria-hidden="true">&times;</span>
		  </button><div class="photobox"></div><div>${data[config.name]}</div><p>(${
              data[config.relation]
            })</p></></td>`
        )
        .filter((data) => data)
        .join("")}</tr>`
    );
  }
  return html.join("");
};

export const generateEditedPreview = (paper, formData, user, fiscalyear) => {
  let nagarpalikaName_en = user.profile?.nagarpalika_info?.name_en;
  let nagarpalikaName_np = user.profile?.nagarpalika_info?.name_np;
  let nagarpalikaAddress_en = user.profile?.nagarpalika_info?.address_en;
  let nagarpalikaAddress_np = user.profile?.nagarpalika_info?.address_np;
  let ward_name_en = user.profile?.ward_info?.name_en;
  let ward_name_np = user.profile?.ward_info?.name_np;

  let metaData = paper.meta;
  const keys = Object.keys(formData);
  const tempHtml = new DOMParser().parseFromString(
    paper.previewData,
    "text/html"
  );
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  keys.forEach((key) => {
    const keyDoms = tempHtml.querySelectorAll(`#${key}`);
    if (keyDoms?.length) {
      keyDoms.forEach((keyDom) => {
        if (key.startsWith("fullname")) {
          keyDom.innerHTML = `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`;
        } else if (key.includes("_logo")) {
          return formData[key];
        } else if (key.startsWith("date")) {
          const properties = key.split("-");
          key = key.split("-")[0];
          if (typeof formData[key] === "object") {
            const hasValue =
              formData[key].year && formData[key].month && formData[key].day;
            if (keyDom) {
              if (!hasValue) {
                return "";
              }
              let joinedDate = `${formData[key].year} | ${formData[key].month} | ${formData[key].day}`;
              const isEnglish = formData[key].year <= new Date().getFullYear(); // ad years are less than bs year.
              try {
                let isEnglish = formData[key].year <= new Date().getFullYear; // ad years are less than bs year.
                let englishified = englishNumberConverter(joinedDate);
                let hasNepalSambat = false;
                if (key.includes("today")) {
                  if (englishified.length > 8) {
                    hasNepalSambat = true;
                    let [yyyy, mm, dd] = englishified.split(" | ");
                    let ad;
                    if (!isEnglish) {
                      ad = new NepaliDate(+yyyy, +(mm - 1), +dd).getAD();
                      ad = `${ad.year}-${("0" + (ad.month + 1)).slice(-2)}-${(
                        "0" + ad.date
                      ).slice(-2)}`;
                    }
                    let nepalSambatDate = NEPAL_SAMBAT[ad];
                    if (nepalSambatDate) {
                      if (properties.includes("same_line")) {
                        joinedDate += `<span> ( ने.सं. :- ${nepalSambatDate} )</span>`;
                      } else {
                        joinedDate = `<span class='fs-26'>मिती: ${joinedDate}</span>`;
                        joinedDate += `<div style='text-align: left;' class='fs-26'> ने.सं. :- ${nepalSambatDate}</div>`;
                      }
                    }
                  }
                  if (hasNepalSambat) {
                    joinedDate = `<span style=' width:100%; text-align: left; display: inline-block;'>${joinedDate}</span>`;
                  }
                }
              } catch (error) {
                console.log(error, "nepal sambat");
              }
              if (properties.includes("postfix")) {
                if (isEnglish) {
                  joinedDate = `${formData[key].year} Year ${formData[key].month} Month ${formData[key].day} Day`;
                } else {
                  joinedDate = `${formData[key].year} साल ${formData[key].month} महिना ${formData[key].day} गते`;
                }
              }
              keyDom.innerHTML = joinedDate;
            }
          } else {
            keyDom.innerHTML = formData[key];
          }
        } else if (key.startsWith("dob")) {
          if (typeof formData[key] === "object") {
            keyDom.innerHTML = Object.values(formData[key]).join("/ ");
          } else {
            keyDom.innerHTML = formData[key];
          }
        } else if (key === "nagarpalika_name_np") {
          keyDom.innerHTML = nagarpalikaName_np;
        } else if (key === "nagarpalika_name_en") {
          keyDom.innerHTML = nagarpalikaName_en;
        } else if (key === "nagarpalika_address_np") {
          keyDom.innerHTML = nagarpalikaAddress_np;
        } else if (key === "nagarpalika_address_en") {
          keyDom.innerHTML = nagarpalikaAddress_en;
        } else if (key === "ward_name_np") {
          keyDom.innerHTML = ward_name_np;
        } else if (key === "ward_name_en") {
          keyDom.innerHTML = ward_name_en;
        } else if (key === "fiscal_year") {
          keyDom.innerHTML = fiscalyear;
        } else if (metaData && metaData[key]) {
          keyDom.innerHTML = metaData[key];
        }

        // is handled in the template itself
        // else if (key.startsWith('post')) {
        // 	return '';
        // } else if (key.startsWith('admin_name')) {
        // 	return '';
        // }
        else if (key.startsWith("post")) {
          let post;
          try {
            // ward_admin => admin-name_admin-post ,, ram_adhakchya
            post = formData["ward_admin"]?.split("_")[1];
            if (post) {
              keyDom.innerHTML = post;
              return;
            } else {
              post = "";
            }
          } catch (err) {
            post = "";
          }
          keyDom.innerHTML = post;
        } else if (key.startsWith("admin_name")) {
          let admin_name;
          try {
            admin_name = formData["ward_admin"]?.split("_")[0];
            if (admin_name) {
              keyDom.innerHTML = admin_name;

              return;
            } else {
              admin_name = "";
            }
          } catch (err) {
            admin_name = "";
          }
          keyDom.innerHTML = admin_name;
        } else if (key === "relation") {
          let sex = formData["select2"];
          if (sex === "male") {
            keyDom.innerHTML = "छोरा";
          } else if (sex === "female") {
            keyDom.innerHTML = "छोरी";
          } else {
            keyDom.innerHTML = "पुत्र";
          }
        } else if (key === "respect") {
          let sex = formData["select4"];
          if (sex === "male") {
            keyDom.innerHTML = "श्रीमान";
          } else if (sex === "female") {
            keyDom.innerHTML = "श्री";
          } else {
            keyDom.innerHTML = "श्री";
          }
        } else if (key === "age") {
          // 1997-01-22
          let age = moment().diff(
            Object.values(formData["date_of_birth_AD"]).join("-"),
            "years"
          );
          keyDom.innerHTML = nepaliNumber(age);
        } else if (key.startsWith("table")) {
          let tableContainerHtml = '<div class="content-container">';
          let tableData = "";
          let tableKeys = [key];
          tableKeys.forEach((tableKey) => {
            if (formData[tableKey]?.length > 0) {
              tableData +=
                '<table class="table table-bordered" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
              const headings = Object.keys(formData[tableKey][0]);
              // const costumeKey = "table";
              // "table__r__1,2"
              const costumeKey = tableKey;

              // eslint-disable-next-line no-unused-vars
              const [_, relationFields] = costumeKey.split("__r__");
              let name, relation;

              if (relationFields) {
                [name, relation] = relationFields.split("_");
              }
              const sortedHeadings = headings.sort(collator.compare);
              const headingData = sortedHeadings
                .map((heading, headingIndex) =>
                  headingIndex === 0
                    ? `<th style="width:60px;">${heading
                        .split("___")[1]
                        .split("####")
                        .join(".")
                        .split("&&&&")
                        .join("'")
                        .split("$$$$")
                        .join(",")}</th>`
                    : `<th>${heading
                        .split("___")[1]
                        .split("####")
                        .join(".")
                        .split("&&&&")
                        .join("'")
                        .split("$$$$")
                        .join(",")}</th>`
                )
                .join("");
              tableData += headingData;
              const rows = formData[tableKey].map(
                (rowData) =>
                  `<tr>${headings
                    .map((heading, headingIndex) =>
                      headingIndex === 0
                        ? `<td style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                        : `<td>${rowData[heading]}</td>`
                    )
                    .join("")}</tr>`
              );
              if (relationFields) {
                tableData += `</tr>${rows.join("")}</table></div>
              <div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0" align='center'>${generateNameRelationBox(
                formData[tableKey],
                {
                  name: sortedHeadings[name],
                  relation: sortedHeadings[relation],
                }
              )}</table> <br/>`;
              } else {
                tableData += `</tr>${rows.join("")}</table> <br/>`;
              }
            } else {
              return null;
            }
          });
          tableContainerHtml += tableData + "</div>";
          keyDom.innerHTML = tableContainerHtml;
        } else {
          keyDom.innerHTML = formData[key];
        }
      });
    }
  });
  return tempHtml.body.innerHTML;
};

export const generatePreview = (
  paper,
  formData,
  user,
  titles = {},
  fiscalyear,
  isEnglish,
  nonEditable
) => {
  let nagarpalikaName_en = user.profile?.nagarpalika_info?.name_en;
  let nagarpalikaName_np = user.profile?.nagarpalika_info?.name_np;
  let nagarpalikaAddress_en = user.profile?.nagarpalika_info?.address_en;
  let nagarpalikaAddress_np = user.profile?.nagarpalika_info?.address_np;
  let ward_name_en = user.profile?.ward_info?.name_en;
  let ward_name_np = user.profile?.ward_info?.name_np;
  let ward_email = user.profile?.ward_info?.email;
  let ward_address_np = user.profile?.ward_info?.address_np;
  let ward_address_en = user.profile?.ward_info?.address_en;
  let ward_secretary_phone = isEnglish
    ? user.profile?.ward_info?.ward_user_phone_en
    : user.profile?.ward_info?.ward_user_phone_np;
  let ward_chairperson_phone = isEnglish
    ? user.profile?.ward_info?.ward_admin_phone_en
    : user.profile?.ward_info?.ward_admin_phone_np;

  let emailLabel = isEnglish ? "Email" : "इमेल";
  let wardSecretaryLabel = isEnglish ? "Ward Secretary" : "वडा सचिब";
  let wardChairpersonLabel = isEnglish ? "Ward Chairperson" : "वडा अध्यक्ष";

  let tempPreviewData = paper.preview;
  let metaData = paper.meta;
  let curlyBraceGraberRegex = /\{\{(.*?)\}\}/g; // {{text1}}
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  tempPreviewData = tempPreviewData.replace(curlyBraceGraberRegex, (value) => {
    let key = value.replace(/[^0-9a-zA-Z_-]+/g, ""); // text1
    if (key.startsWith("fullname")) {
      return generateUneditable(
        formData[key] &&
          `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`,
        key,
        nonEditable
      );
    } else if (key.includes("_logo")) {
      return formData[key];
    } else if (key.startsWith("date")) {
      const trueKey = key;

      const properties = trueKey.split("-");
      key = key.split("-")[0];
      if (typeof formData[key] === "object") {
        const hasValue =
          formData[key].year && formData[key].month && formData[key].day;

        if (!hasValue) {
          return "";
        }

        let joinedDate = `${formData[key].year} | ${formData[key].month} | ${formData[key].day}`;
        isEnglish = isEnglish || formData[key].year <= new Date().getFullYear(); // ad years are less than bs year.
        try {
          let englishified = englishNumberConverter(joinedDate);
          let hasNepalSambat = false;
          if (key.includes("today")) {
            if (englishified.length > 8) {
              let [yyyy, mm, dd] = englishified.split(" | ");
              let ad;
              if (!isEnglish) {
                ad = new NepaliDate(+yyyy, +(mm - 1), +dd).getAD();
                ad = `${ad.year}-${("0" + (ad.month + 1)).slice(-2)}-${(
                  "0" + ad.date
                ).slice(-2)}`;
              }
              hasNepalSambat =
                !properties.includes("no_nep_samb") && !key.includes("_en");
              let nepalSambatDate = hasNepalSambat && NEPAL_SAMBAT[ad];

              if (properties.includes("dMy_format")) {
                joinedDate = `${formData[key].day} ${moment()
                  .month(formData[key].month - 1)
                  .format("MMM")} ${formData[key].year}`;
              }

              if (nepalSambatDate) {
                hasNepalSambat = true;
                if (properties.includes("same_line")) {
                  joinedDate += `<span> ( ने.सं. :- ${nepalSambatDate} )</span>`;
                } else {
                  joinedDate = `<span class='fs-26'>${
                    isEnglish ? "" : "मिती:"
                  } ${joinedDate}</span>`;
                  joinedDate += `<div style='text-align: left;' class='fs-26'> ने.सं. :- ${nepalSambatDate}</div>`;
                }
              }
            }
            if (hasNepalSambat) {
              joinedDate = `<span style='text-align: left;'>${joinedDate}</span>`;
            }
          }
        } catch (error) {
          console.log(error, "nepal sambat");
        }
        if (properties.includes("postfix")) {
          if (isEnglish) {
            joinedDate = `${formData[key].year} Year ${formData[key].month} Month ${formData[key].day} Day`;
          } else {
            joinedDate = `${formData[key].year} साल ${formData[key].month} महिना ${formData[key].day} गते`;
          }
        }
        return generateUneditable(joinedDate, trueKey);
      } else {
        return generateUneditable(formData[key], trueKey);
      }
    } else if (key.startsWith("dob")) {
      if (typeof formData[key] === "object") {
        let joinedDate = `${formData[key].year}/${formData[key].month}/${formData[key].day}`;
        return generateUneditable(joinedDate, key);
      } else {
        return generateUneditable(formData[key], key);
      }
    } else if (key === "nagarpalika_name_np") {
      return generateUneditable(nagarpalikaName_np, key);
    } else if (key === "nagarpalika_name_en") {
      return generateUneditable(nagarpalikaName_en, key);
    } else if (key === "nagarpalika_address_np") {
      return generateUneditable(nagarpalikaAddress_np, key);
    } else if (key === "nagarpalika_address_en") {
      return generateUneditable(nagarpalikaAddress_en, key);
    } else if (key === "ward_name_np") {
      return generateUneditable(ward_name_np, key);
    } else if (key === "ward_name_en") {
      return generateUneditable(ward_name_en, key);
    } else if (key === "ward_email") {
      return generateUneditable(ward_email, key);
    } else if (key === "ward_address_np") {
      return generateUneditable(ward_address_np, key);
    } else if (key === "ward_address_en") {
      return generateUneditable(ward_address_en, key);
    } else if (key === "emailLabel") {
      return emailLabel;
    } else if (key === "wardSecretaryLabel") {
      return wardSecretaryLabel;
    } else if (key === "ward_secretary_phone") {
      return generateUneditable(ward_secretary_phone, key);
    } else if (key === "wardChairpersonLabel") {
      return wardChairpersonLabel;
    } else if (key === "ward_chairperson_phone") {
      return generateUneditable(ward_chairperson_phone, key);
    } else if (key === "fiscal_year") {
      return generateUneditable(
        isEnglish ? fiscalyear : nepaliNumberConverter(fiscalyear),
        key
      );
    } else if (key === "serial_number") {
      return generateUneditable(
        isEnglish ? metaData[key] : nepaliNumberConverter(metaData[key]),
        key
      );
    } else if (metaData && metaData[key]) {
      return generateUneditable(metaData[key], key);
    }

    // is handled in the template itself
    // else if (key.startsWith('post')) {
    // 	return '';
    // } else if (key.startsWith('admin_name')) {
    // 	return '';
    // }
    else if (key.startsWith("post")) {
      let post;
      try {
        // ward_admin => admin-name_admin-post ,, ram_adhakchya
        post = formData["ward_admin"]?.split("_")[1];
        if (post) {
          return generateUneditable(post, key);
        } else {
          post = "";
        }
      } catch (err) {
        post = "";
      }
      return generateUneditable(post, key);
    } else if (key.startsWith("admin_name")) {
      let admin_name;
      try {
        admin_name = formData["ward_admin"]?.split("_")[0];
        if (admin_name) {
          return admin_name;
        } else {
          admin_name = "";
        }
      } catch (err) {
        admin_name = "";
      }
      return generateUneditable(admin_name, key);
    } else if (key === "relation") {
      let sex = formData["select2"];
      if (sex === "male") {
        return generateUneditable("छोरा", key);
      } else if (sex === "female") {
        return generateUneditable("छोरी", key);
      } else {
        return generateUneditable("पुत्र", key);
      }
    } else if (key === "respect") {
      let sex = formData["select4"];
      if (sex === "male") {
        return generateUneditable("श्रीमान", key);
      } else if (sex === "female") {
        return generateUneditable("श्री", key);
      } else {
        return generateUneditable("श्री", key);
      }
    } else if (key === "age") {
      // 1997-01-22
      let ad = formData["date_of_birth_AD"];
      if (ad) {
        let age = moment().diff(
          Object.values(formData["date_of_birth_AD"]).join("-"),
          "years"
        );
        return generateUneditable(nepaliNumber(age), key);
      }
      return formData[key] ?? "";
    } else if (key.startsWith("table")) {
      let tableContainerHtml = '<div class="content-container text-center">';
      let tableData = "";
      let tableKeys = [key];
      tableKeys.forEach((tableKey) => {
        let id;
        try {
          const [k, _id] = tableKey.split("-");
          if (_id) {
            id = _id;
            console.log(k);
          }
        } catch (e) {}
        if (id) {
          if (titles[id]) {
            tableContainerHtml += `<strong class=' text-dark'>${titles[id]}</strong>`;
          }
        }
        if (formData[tableKey]?.length > 0) {
          tableData +=
            '<table class="table table-bordered preview-page-table" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
          const headings = Object.keys(formData[tableKey][0]);
          // const costumeKey = "table";
          // "table__r__1,2"
          const costumeKey = tableKey;

          // eslint-disable-next-line no-unused-vars
          const [_, relationFields] = costumeKey.split("__r__");
          let name, relation;

          if (relationFields) {
            [name, relation] = relationFields.split("_");
          }

          const sortedHeadings = headings.sort(collator.compare);
          const headingData = sortedHeadings
            .map((heading, headingIndex) =>
              headingIndex === 0
                ? `<th style="width:60px;">${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")
                    .split("$$$$")
                    .join(",")}</th>`
                : `<th>${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")
                    .split("$$$$")
                    .join(",")}</th>`
            )
            .join("");

          tableData += headingData;
          const rows = formData[tableKey].map(
            (rowData) =>
              `<tr>${headings
                .map((heading, headingIndex) =>
                  headingIndex === 0
                    ? `<td style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                    : `<td>${rowData[heading]}</td>`
                )
                .join("")}</tr>`
          );
          if (relationFields) {
            tableData += `</tr>${rows.join("")}</table></div>
            <div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0">${generateNameRelationBox(
              formData[tableKey],
              {
                name: sortedHeadings[name],
                relation: sortedHeadings[relation],
              }
            )}</table> <br/>`;
          } else {
            tableData += `</tr>${rows.join("")}</table> <br/>`;
          }
        } else {
          return null;
        }
      });
      tableContainerHtml += tableData + "</div>";

      return generateUneditable(tableContainerHtml, key);
    } else {
      return generateUneditable(formData[key], key);
    }
  });
  return tempPreviewData;
};

export function getDifference(a, b) {
  var i = 0;
  var j = 0;
  var result = "";
  let position;
  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) {
      result = b[j];
      position = j;
      break;
    } else i++;
    j++;
  }
  return { result, position };
}

export function normalizeHtml(str) {
  return (
    str &&
    str
      .replace(/&nbsp;|\u202F|\u00A0/g, " ")
      .replace(/&lt;/g, "ÿ") // < and <</p> causes a problem because 1st character is equal
      //and it always takes second one so this little hack prevents using  < at any cost
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
  );
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

export function setCurrentCursorPosition(
  chars,
  preview = "preview",
  insertCharacter
) {
  if (chars >= 0) {
    const selection = window.getSelection();

    let range = createRange(document.getElementById(preview).parentNode, {
      count: chars,
    });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export function getCurrentCursorPosition() {
  const element = document.getElementById("preview");
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== "Control") {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export const debounce = (func, delay = 500) => {
  let timeout;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const urlPublicChecker = (url, isPublic = false) => {
  if (isPublic) {
    return "/public" + url;
  }
  return url;
};

export const insertSubStringAtString = (index, string, subString) => {
  let result = string.split("");
  result.splice(index, 0, subString);
  return result.join("");
};

export const isObjectEmpty = (objectName) => {
  return JSON.stringify(objectName) === "{}";
};

export const isEnglishChecker = (string) => {
  return string.match(/[a-zA-Z]/g);
};

export const nepalSambatDate = (date, isEnglish) => {
  if (!date.year || !date.month || !date.day) {
    return "";
  }
  let joinedDate = `${date.year} | ${date.month} | ${date.day}`;
  let englishified = englishNumberConverter(joinedDate);
  let hasNepalSambat = false;
  if (englishified.length > 8) {
    hasNepalSambat = true;
    let [yyyy, mm, dd] = englishified.split(" | ");
    let ad;
    if (!isEnglish) {
      console.log(yyyy, mm, dd);
      ad = new NepaliDate(+yyyy, mm - 1, +dd).getAD();
      ad = `${ad.year}-${("0" + (ad.month + 1)).slice(-2)}-${(
        "0" + ad.date
      ).slice(-2)}`;
    }
    let nepalSambatDate = NEPAL_SAMBAT[ad];
    if (nepalSambatDate) {
      joinedDate += `<span class="nepal-sambat"><br/> ने.सं. :- ${nepalSambatDate}</span>`;
    }
  }
  if (hasNepalSambat) {
    return `<span style=''>${joinedDate}</span>`;
  }
  return joinedDate;
};

export function englishNumberConverter(numString) {
  let englishNumber = "";
  for (let i = 0; i < numString.length; i++) {
    let num = numString[i];
    let englishNum = nepaliNumbers.indexOf(num);
    if (englishNum < 0) {
      englishNumber += num;
    } else {
      englishNumber += englishNum;
    }
  }
  return englishNumber;
}

export const dateParser = (date, isEnglish) => {
  let joinedDate = `${date.year} | ${date.month} | ${date.day}`;
  return joinedDate;
};
