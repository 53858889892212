import { authHeader } from "./auth-header";
import { apiKey } from "../constant";

let COMMON_URL = process.env.REACT_APP_BACKEND_URL;

async function get(url, isPublic) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(isPublic),
  };
  let URL = COMMON_URL + url;
  let response = await fetch(URL, requestOptions);
  let handledResponse = await handleResponse(response);
  // console.log(handledResponse, 'HandleResponse')
  return handledResponse;

}

function post(url, body, contentType, isPublic) {
  let user = JSON.parse(localStorage.getItem("user"));
  let headers = {
    apikey: apiKey
  };
  if (!isPublic) {
    headers.Authorization = `bearer ${user?.token}`;
  }

  const requestOptions = {
    method: "POST",
    headers: contentType
      ? headers
      : authHeader(isPublic),
    body: contentType ? body : JSON.stringify(body),
  };

  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function patch(url, body, contentType) {
  let user = JSON.parse(localStorage.getItem("user"));
  try {
    const requestOptions = {
      method: "PATCH",
      headers: contentType
        ? {
          Authorization: `Bearer ${user.token}`,
          apikey: apiKey,
        }
        : authHeader(),
      body: contentType ? body : JSON.stringify(body),
    };
    const response = fetch(COMMON_URL + url, requestOptions).then(
      handleResponse
    );
    return response;
  } catch (error) {
    console.log(error, "error");
  }
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
  // if (response.status === 403) {
  //   toast.error("तपाईलाई यस कार्यको अनुमति छैन।");
  //   return Promise.reject(response);
  // }
  // if (response.status === 401) {
  //   toast.error("माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन |");
  //   return Promise.reject(response);
  // }
  // if (response.status === 500) {
  //   toast.error("यो कार्य हाल सम्भव छैन। केहि समय पछि पुन: प्रयास गर्नुहोस्।");
  //   return Promise.reject(response);
  // }

  return response.text().then((text) => {
    try {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data) || response;
        throw error;
      }
      return data;
    } catch (e) {
      throw response;
    }
  }).catch(error => {
    throw error;
  });
}

export const fetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};
