import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { jwtCheck, setToken } from '../../_helpers/jwt-check';
import { setUiError, setUiMessage } from './ui';
import { toast } from 'react-toastify';

export const initialState = {
	loading: false,
	hasErrors: false,
	users: [],
	user: null,
	isLoggedIn: jwtCheck() ? true : false,
};

// A slice
const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		updateUser: (state, action) => {
			let tempuser = {
				...state.user,
				email: action.payload.email,
				username: action.payload.email,
				phone: action.payload.phone_np,
				profile: {
					...state.user.profile,
					fullname_np: action.payload.fullname_np,
					fullname_en: action.payload.fullname_en,
					fullname: action?.payload?.fullname || action.payload.fullname_np,
					email: action.payload.email,
					phone_en: action.payload.phone_en,
					phone_np: action.payload.phone_np,
				},
			};
			state.user = tempuser;
		},
		setUserLogin: (state, action) => {
			state.isLoggedIn = true;
		},
		// setUserLogout: (state, action) => initialState,
		setUserLogout: (state, action) => {
			state.user = null;
			state.isLoggedIn = false;
		},
		addUser: (state, action) => {
			state.users.unshift(action.payload);
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getUsersSuccess: (state, action) => {
			state.users = action.payload;
			state.loading = false;
			state.hasErrors = false;
		},
		getUsersFailure: (state) => {
			state.loading = false;
			//handling Errors
			state.hasErrors = true;
		},
	},
});

// Actions generated from the slice
const {
	setUser,
	setUserLogin,
	setUserLogout,
	addUser,
	startLoading,
	stopLoading,
	getUsersFailure,
	getUsersSuccess,
	updateUser,
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.users;
export const userSelector = (state) => state.users.user;

// export The reducer
const userReducer = usersSlice.reducer;

export default userReducer;

// Actions
export const fetchUsers = () => async (dispatch) => {
	try {
		dispatch(startLoading());
		const response = await fetch('https://jsonplaceholder.typicode.com/users');
		const data = await response.json();
		dispatch(getUsersSuccess(data));
	} catch (error) {
		dispatch(getUsersFailure());
	}
};

export const createUser = (User) => async (dispatch) => {
	try {
		dispatch(addUser(User));
	} catch (error) {
		dispatch(getUsersFailure());
	}
};

export const patchWardUser = (data, id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.patch(`/ward-user/${id}/`, data);
		dispatch(updateUser(response.data));
		dispatch(setUiMessage(response.message));
	} catch (error) {
		let errorMessage = error.error?.non_field_error || error.message;
		dispatch(setUiError(errorMessage));
	}
};
export const patchITOAdminUser = (data, id) => async (dispatch) => {
	try {
		const response = await fetchWrapper.patch(`/ito/${id}/`, data);
		dispatch(updateUser(data));
		dispatch(setUiMessage(response.message));
	} catch (error) {
		let errorMessage = error.error?.non_field_error || error.message;
		dispatch(setUiError(errorMessage));
	}
};

export const loginUser = (credentials, history) => async (dispatch) => {
	try {
		dispatch(startLoading());
		let response = await fetchWrapper.post('/auth/jwt/create/', credentials);
		let accessToken = {
			token: response.data.access,
		};
		let refreshToken = {
			token: response.data.refresh,
		};
		let role = response.data.role;
		// let
		setToken(accessToken, refreshToken, role);
		dispatch(stopLoading());
		dispatch(setUserLogin(response.data));
		history.push('/');
	} catch (err) {
		dispatch(stopLoading());
		if (err.status === 401) {
			toast.error('माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन।');
			return Promise.reject(err);
		}
		if (err.status === 500) {
			toast.error('यो कार्य हाल सम्भव छैन। केहि समय पछि पुन: प्रयास गर्नुहोस्।');
			return Promise.reject(err);
		}
		dispatch(stopLoading());
	}
};
export const loginUserWIthSSO = (user, history) => async (dispatch) => {
	try {
		dispatch(startLoading());
		let accessToken = {
			token: user.access,
		};
		let refreshToken = {
			token: user.refresh,
		};
		let role = user.role;
		// let
		setToken(accessToken, refreshToken, role);
		dispatch(stopLoading());
		dispatch(setUserLogin(user));
		history.push('/');
	} catch (err) {
		dispatch(stopLoading());
		let errorMessage = err.error?.non_field_error || err.message;
		dispatch(setUiError(errorMessage));
	}
};

export const getUserData = () => async (dispatch) => {
	try {
		const user = await fetchWrapper.get(`/user/me/`);
		dispatch(setUser(user.data));
	} catch (err) {
		dispatch(setUserLogout());
	}
};

export const logoutUser = () => async (dispatch) => {
	localStorage.removeItem('user');
	localStorage.removeItem('userRefresh');
	localStorage.removeItem('userId');
	localStorage.removeItem('messagePermission');
	localStorage.setItem('showLoginPage', true);

	dispatch(setUserLogout());
};
