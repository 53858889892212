import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage, setUiError } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  wards: [],
  count: 0,
  wardUsers: {},
};

// A slice
const wardSlice = createSlice({
  name: "wards",
  initialState,
  reducers: {
    getWards: (state, action) => {
      state.wards = action.payload.data.results;
      state.count = action.payload.data.count;
    },
    getWardUsers: (state, action) => {
      state.wardUsers = action.payload.data.data;
    },
    createWardUser: (state, action) => {
      state.wardUsers.users.push(action.payload.data.users[0]);
    },
    editWardUser: (state, action) => {
      const editedUser = { ...state.wardUsers };

      const editedIndex = editedUser.users.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        editedUser.users[editedIndex] = action.payload;
      }
      state.wardUsers = editedUser;
    },
    activeWardUser: (state, action) => {
      const tempUser = { ...state.wardUsers };
      const editedIndex = tempUser.users.findIndex(
        (user) => user.id === action.payload.profile_id
      );
      if (editedIndex !== -1) {
        if (action.payload.status === "active") {
          tempUser.users[editedIndex].is_active = true;
        } else {
          tempUser.users[editedIndex].is_active = false;
        }
      }
      state.wardUsers = tempUser;
    },
    deleteWardUser: (state, action) => {
      const tempUsers = { ...state.wardUsers };
      const index = tempUsers.users.findIndex(
        (user) => user.id === action.payload
      );
      tempUsers.users.splice(index, 1);
      state.wardUsers = tempUsers;
    },
  },
});

// Actions generated from the slice
const {
  getWards,
  getWardUsers,
  createWardUser,
  activeWardUser,
  editWardUser,
  deleteWardUser,
} = wardSlice.actions;

// export user selector to get the slice in any component
export const wardSelector = (state) => state.wards;

// export The reducer
const wardReducer = wardSlice.reducer;

export default wardReducer;

// Actions
export const fetchWards =
  (page = 1) =>
  async (dispatch) => {
    try {
      const wards = await fetchWrapper.get(`/ward/?page=${page}`);
      dispatch(getWards(wards));
    } catch (error) {}
  };
export const fetchWardUsers = (id) => async (dispatch) => {
  try {
    const wardUsers = await fetchWrapper.get(`/ward/${id}/`);
    dispatch(getWardUsers(wardUsers));
  } catch (error) {}
};

export const postWardUser = (data, hideUserForm, id) => async (dispatch) => {
  try {
    let body = {
      users: [
        {
          username: data.email,
          email: data.email,
          password: data.password,
          role: data.role,
          phone: data.phone_en,
          profile: {
            post: data.post_en,
            fullname_en: data.fullname_en,
            fullname_np: data.fullname_np,
            address: data.address,
            phone_en: data.phone_en,
            phone_np: data.phone_np,
            post_en: data.post_en,
            post_np: data.post_np,
          },
        },
      ],
    };
    const response = await fetchWrapper.post(`/ward/${id}/create_user/`, body);
    dispatch(createWardUser(response));
    dispatch(setUiMessage(response.message));
    hideUserForm();
  } catch (error) {
    if (error.error?.non_field_error) {
      dispatch(setUiError(error.error.non_field_error));
    } else {
      if (error?.error && Object.keys(error.error).length) {
        Object.keys(error.error).map((errorKey) => {
          console.log(error.error[errorKey][0], "error.error[errorKey][0]");
          return toast.error(error.error[errorKey][0]);
        });
      }

      // dispatch(setUiMessage(error.message));
    }
  }
};

export const patchWardUser =
  (data, id, setShowAdd, showAdd) => async (dispatch) => {
    try {
      if (data.password === "") {
        delete data.password;
      }
      const response = await fetchWrapper.patch(`/ward-user/${id}/`, data);
      // console.log(response, data);

      dispatch(editWardUser({ id, ...data }));
      setShowAdd(false);
      dispatch(setUiMessage(response.message));
    } catch (error) {
      console.log(error, "error");
      dispatch(setUiError(error.message));
    }
  };

export const activateKawaWardUser = (body) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(`/update-kawa-status/`, body);
    dispatch(activeWardUser(body));
    dispatch(setUiMessage(response.message));
  } catch (error) {}
};

export const removeWardUser = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/ward-user/${id}/`);

    dispatch(deleteWardUser(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {}
};
