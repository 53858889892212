import * as Yup from 'yup';
// import { array, object } from "yup/lib/locale";

export const logValidationSchema = Yup.object().shape({
	email: Yup.string().required('email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

export const wardValidationSchema = Yup.object().shape({
	name_np: Yup.string().required('Name is required'),
	name_en: Yup.string().required('Name is required'),
	email: Yup.string().email('Email must be valid').required('Email is required'),
	phone_np: Yup.string().length(10).required('Phone number is required'),
	phone_en: Yup.string().length(10).required('Phone number is required'),
	address_np: Yup.string().required('Address is required'),
	address_en: Yup.string().required('Address is required'),
	users: Yup.array().of(
		Yup.object().shape({
			username: Yup.string().required('Name required'),
			profile: Yup.object().shape({
				fullname_np: Yup.string().required('Full Name is required'),
				fullname_en: Yup.string().required('Full Name is required'),
				phone: Yup.string().length(10).required('Phone number is required'),
				post_np: Yup.string().required('Post is required'),
				post_en: Yup.string().required('Post is required'),
			}),
			email: Yup.string().required('email required').email('Enter valid email'),
			password: Yup.string().min(8).required('Password is required'),
		})
	),
});

export const mayorValidationSchema = Yup.object().shape({
	nagarpalika: Yup.string().required('Nagarpalika is required'),
	fullname_np: Yup.string().required('Name is required'),
	fullname_en: Yup.string().required('Name is required'),
	phone_en: Yup.string().length(10).required('Phone number is required'),
	address_np: Yup.string().required('Address is required'),
	address_en: Yup.string().required('Address is required'),
});
export const changePasswordValidation = Yup.object().shape({
	currentPassword: Yup.string()
		.required({ en: 'Password is required', np: 'हालको पासवर्ड अनिवार्य छ' })
		.min(8, { en: 'Password must be at least 8 characters', np: 'पासवर्ड 8 वर्णको हुनुपर्छ' }),
	// .matches(/\d/, "Password must contain atleast one number")
	// .matches(/[a-zA-Z]/, "Password must contain atleast one letter")
	password: Yup.string()
		// .matches(/\d/, "Password must contain atleast one number")
		// .matches(/[a-zA-Z]/, "Password must contain atleast one letter")
		.required({ en: 'Password is required', np: 'नयाँ पासवर्ड अनिवार्य छ' })
		.min(8, { en: 'Password must be at least 8 characters', np: 'पासवर्ड 8 वर्णको हुनुपर्छ' })
		.notOneOf([Yup.ref('currentPassword'), null], {
			en: 'New Password cannot be same as Old Password',
			np: 'नयाँ पासवर्ड, हाल पासवर्डभन्दा फरक हुनुपर्छ',
		}),
	confirmPassword: Yup.string()
		.required({ en: 'Confirm Password is required', np: 'पुन: नयाँ पासवर्ड अनिवार्य छ' })
		.min(8, { np: 'पासवर्ड 8 वर्णको हुनुपर्छ', en: 'Password must be at least 8 characters' })
		.oneOf([Yup.ref('password'), null], {
			en: 'Confirm Password must match with New Password',
			np: 'नयाँ पासवर्ड समान हुनुपर्छ',
		}),
});

export const documentTypeValidation = Yup.object().shape({
	title: Yup.string().required('Title is required'),
});

export const sabikaValidation = Yup.object().shape({
	title: Yup.string().required('शीर्षक आवश्यक छ'),
	// address: Yup.string().required('ठेगाना आवश्यक छ'),
});

export const applicantValidationSchema = Yup.object().shape({
	phone: Yup.string().length(10).required('Phone number is required'),
	email: Yup.string().email('Email must be valid').required('Email is required'),
	password: Yup.string().required('Password is required'),
	first_name_np: Yup.string().required('First name is required'),
	middle_name_np: Yup.string().required('Middle name is required'),
	last_name_np: Yup.string().required('Last name is required'),
	first_name_en: Yup.string().required('First name is required'),
	middle_name_en: Yup.string().required('Middle name is required'),
	last_name_en: Yup.string().required('Last name is required'),
	citizenship_number: Yup.string().required('Citizenship number is required'),
	citizenship_issue_district: Yup.string().required('Citizenship issue district is required'),
	citizenship_id: Yup.string().required('Citizenship id is required'),
	// image:Yup.string().required("Image is required"),
	province: Yup.string().required('Province is required'),
	district: Yup.string().required('District is required'),
	ward: Yup.string().required('Ward is required'),
});
