import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError, setUiMessage } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  totalFiscalYear: 0,
  fiscalyear: [],
  activeFiscalYear: null,
};

// A slice
const fiscalSlice = createSlice({
  name: "fiscalyear",
  initialState,
  reducers: {
    setFiscalYear: (state, action) => {
      state.fiscalyear = action.payload.data.results;
      state.totalFiscalYear = action.payload.data.count;
    },
    setActiveFiscalYear: (state, action) => {
      state.activeFiscalYear = action.payload;
    },
    patchFiscalYear: (state, action) => {
      let tempFiscalYear = [...state.fiscalyear];
      const index = tempFiscalYear.findIndex(
        (fiscal) => fiscal.id === action.payload.id
      );
      if (index !== -1) {
        tempFiscalYear = tempFiscalYear.map((year) => ({
          ...year,
          is_active: false,
        }));
        tempFiscalYear[index] = action.payload;
      }
      state.fiscalyear = [...tempFiscalYear];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Actions generated from the slice
const {
  setFiscalYear,
  patchFiscalYear,
  setActiveFiscalYear,
  setLoading,
} = fiscalSlice.actions;

// export user selector to get the slice in any component
export const fiscalSelector = (state) => state.fiscalyear;

// export The reducer
const fiscalReducer = fiscalSlice.reducer;

export default fiscalReducer;

// Actions
export const fetchFiscalYear =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const fiscalyear = await fetchWrapper.get(`/fiscal-year/?page=${page}`);
      dispatch(setFiscalYear(fiscalyear));
      dispatch(setLoading(false));
    } catch (error) {
      // dispatch(getUsersFailure());
      dispatch(setLoading(false));
    }
  };
export const fetchActiveFiscalYear = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const activeFiscalYear = await fetchWrapper.get("/active-fiscal-year/");
    dispatch(setActiveFiscalYear(activeFiscalYear.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const createFiscalYear =
  (data, handleFiscalYearForm) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await fetchWrapper.post("/fiscal-year/", data);
      dispatch(fetchFiscalYear());
      dispatch(setUiMessage('आर्थिक वर्ष सिर्जना गरियो!'));
      dispatch(setLoading(false));
      handleFiscalYearForm();
    } catch (error) {
      if (error?.error?.non_field_error) {
        dispatch(setUiError(error.error.non_field_error));
        dispatch(setLoading(false));
      }
    }
  };
export const editFiscalYear = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await fetchWrapper.patch(`/fiscal-year/${data.id}/`, data);
    if (data.is_active) {
      dispatch(setActiveFiscalYear(data));
    }

    dispatch(patchFiscalYear(response.data));
    dispatch(setUiMessage('आर्थिक वर्ष सक्रिय गरियो!'));
    dispatch(setLoading(false));
  } catch (error) {
    if (error?.error?.non_field_error) {
      dispatch(setUiError(error.error.non_field_error));
      dispatch(setLoading(false));
    }
  }
};

export const deleteFiscalYear = (id) => async (dispatch) => {
  try {
    await fetchWrapper.delete(`/fiscal-year/${id}/`);
    dispatch(fetchFiscalYear());
    dispatch(setUiMessage('आर्थिक वर्ष हटाइयो!'));
  } catch (error) {
    if (error?.error?.non_field_error) {
      dispatch(setUiError(error.error.non_field_error));
    }
  }
};
