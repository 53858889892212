/** @jsxRuntime classic */
/** @jsx jsx */
import {
    css,
    jsx,
    // , css
} from "@emotion/react";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputField from "../../../components/InputField";
import { useSelector } from "react-redux";
import { languageSelector } from "../../../_redux/slices/languages";
import { yupResolver } from "@hookform/resolvers/yup";
import { VscLoading } from "react-icons/vsc";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { sabikaValidation } from "../../../_helpers/schema-validation";
import { StyledInput } from "../../../components/InputField/style/StyledIndex";

const SabikKoPalikaForm = ({ showAdd, setShowAdd, palika, callback }) => {
    const { language } = useSelector(languageSelector);
    const [loading, setLoading] = useState(false);

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(sabikaValidation),
    });

    const onSubmit = async (data) => {
        if (palika) {
            // EDIT
            try {
                setLoading(true);
                await fetchWrapper.patch(`/former-wards/${palika.id}/`, data);
                toast.success('साविकको पालिका सफलतापूर्वक सम्पादन गरियो।');
                setShowAdd(false);
                callback();
            }
            catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false);
            }
        }
        else {
            // CREATE
            try {
                setLoading(true);
                await fetchWrapper.post('/former-wards/', data);
                toast.success('साविकको पालिका सफलतापूर्वक सिर्जना गरियो।');
                setShowAdd(false);
                callback();
            }
            catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (palika) {
            setValue("title", palika.title);
            setValue("title_en", palika.title_en);
            setValue("address", palika.address);
        } else {
            setValue("title", "");
            setValue("title_en", "");
            setValue("address", "");
        }
    }, [setValue, palika]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Card.Header className="card-header-custom">
                    <h3>
                        {palika?.eventType === "edit"
                            ? "साविकको पालिका सम्पादन गर्नुहोस्"
                            : "नयाँ साविकको पालिका"}
                    </h3>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group controlId="formBasicEmail" className="d-flex align-items-center">
                                <span className="mr-3">
                                    शीर्षक:
                                </span>

                                <div className="w-100">
                                    <InputField
                                        css={css`
                                            font-size: 16px !important;
                                        `}
                                        className={`textbox_req`}
                                        language={language}
                                        classNameName={`input`}
                                        elementId={"title"}
                                        placeholder={"Enter Title"}
                                        register={register}
                                        isRequired={true}
                                        setValue={setValue}
                                        autoComplete={"nope"}
                                    />

                                    <Form.Text className="text-danger">
                                        {errors.title?.message}
                                    </Form.Text>
                                </div>

                                <span className="mr-3">
                                    Title:
                                </span>

                                <div className="w-100">
                                    <StyledInput
                                        css={css`
                                            font-size: 16px !important;
                                        `}
                                        className="input textbox_req"
                                        id="title_en"
                                        {...register('title_en')}
                                        required
                                    />
                                    
                                    <Form.Text className="text-danger">
                                        {errors.title_en?.message}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </Col>

                        {/* <Col md={6} className="mb-3">
                            <Form.Group controlId="formBasicEmail" className="d-flex align-items-center">
                                <span className="mr-3">
                                    ठेगाना:
                                </span>

                                <div className="w-100">
                                    <InputField
                                        css={css`
                        font-size: 16px !important;
                      `}
                                        className={`textbox_req`}
                                        language={language}
                                        classNameName={`input`}
                                        elementId={"address"}
                                        placeholder={"Enter address"}
                                        register={register}
                                        isRequired={true}
                                        setValue={setValue}
                                        autoComplete={"nope"}
                                    />

                                    <Form.Text className="text-danger">
                                        {errors.address?.message}
                                    </Form.Text>
                                </div>
                            </Form.Group>
                        </Col> */}
                    </Row>
                </Card.Body>

                <Card.Footer className="d-flex justify-content-end align-items-center">
                    <Button variant="secondary" className="mr-2" onClick={() => setShowAdd(!showAdd)} disabled={loading}>
                        रद्द गर्नुहोस्
                    </Button>
                    <Button type="submit" variant="success" disabled={loading}>
                        {loading ? <VscLoading className="spin" /> : ' पेश गर्नुहोस्'}
                    </Button>
                </Card.Footer>
            </Card>
        </Form>
    );
};

export default SabikKoPalikaForm;
