/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import packageJson from '../../package.json';

const Versioning = ({ color }) => {
	return (
		<div
			css={css`
				text-align: center;
				color: ${color || 'white'};
			`}
		>
			v{packageJson.version}
		</div>
	);
};

export default Versioning;
