/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { upperData, lowerData } from "./SidebarData";
import SidebarButtonRenderer from "./SidebarButtonRenderer";
import PaperTemplateRenderer from "./PaperTemplateRenderer";
import { checkPermission } from "../../_helpers/roles/check-roles";
import { languageSelector } from "../../_redux/slices/languages";

const Sidebar = () => {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const nagarpalikaInfo = useSelector(
    (state) => state.users?.user?.profile?.nagarpalika_info
  );
  const user = useSelector((state) => state.users?.user);
  const { language } = useSelector(languageSelector);
  const isSSO = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true";
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <a
          href={
            isSSO ? `${process.env.REACT_APP_SSO_URL}/application/user` : "/"
          }
        >
          <img
            src={nagarpalikaInfo?.main_logo || "/assets/images/nep_logo.png"}
            className="logo"
            alt=""
          />
        </a>
        {nagarpalikaInfo ? (
          <div>
            <h5>
              {language === "english"
                ? nagarpalikaInfo.name_en
                : nagarpalikaInfo.name_np}
            </h5>
            <p
              css={css`
                font-size: 1.05rem;
              `}
            >
              {language === "nepali" ? (
                <Fragment>
                  {user?.role === "ito_admin" ? (
                    <Fragment>
                      {nagarpalika?.name_en?.includes("Rural")
                        ? "गाउँ "
                        : "नगर "}
                      कार्यपालिकाको कार्यालय
                    </Fragment>
                  ) : user?.profile?.ward_info?.name_np ? (
                    `${user?.profile?.ward_info?.name_np} नं. वडा कार्यालय`
                  ) : null}
                </Fragment>
              ) : (
                <Fragment>
                  {user?.role === "ito_admin" ? (
                    <Fragment>
                      Office Of the{" "}
                      {nagarpalika?.name_en?.includes("Rural")
                        ? "Rural "
                        : "Municipal "}{" "}
                      Executive
                    </Fragment>
                  ) : user?.profile?.ward_info?.name_en ? (
                    `${user?.profile?.ward_info?.name_en} No. Ward Office`
                  ) : null}
                </Fragment>
              )}
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {language === "english"
                ? nagarpalikaInfo.address_en
                : nagarpalikaInfo.address_np}
            </p>
          </div>
        ) : (
          <div>
            <h5>
              {language === "english"
                ? nagarpalika?.name_en
                : nagarpalika?.name_np}
            </h5>
            <p
              css={css`
                font-size: 1.1rem;
              `}
            >
              {language === "nepali" ? (
                <Fragment>
                  {user?.role === "ito_admin" ? (
                    <Fragment>
                      {nagarpalika?.name_en?.includes("Rural")
                        ? "गाउँ "
                        : "नगर "}
                      कार्यपालिकाको कार्यालय
                    </Fragment>
                  ) : (
                    `${user?.profile?.ward_info?.name_np} नं. वडा कार्यालय`
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {user?.role === "ito_admin" ? (
                    <Fragment>
                      Office Of the{" "}
                      {nagarpalika?.name_en?.includes("Rural")
                        ? "Rural "
                        : "Municipal "}{" "}
                      Executive
                    </Fragment>
                  ) : (
                    ` ${user?.profile?.ward_info?.name_en} No. Ward Office`
                  )}
                </Fragment>
              )}
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {language === "english"
                ? nagarpalika?.address_en
                : nagarpalika?.address_np}
            </p>
          </div>
        )}
      </div>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <h4
        css={css`
          text-align: center;
          padding: 0.5rem 0;
          color: black;
        `}
      >
        {language === "english" ? "Sifarish System" : "सिफारिस प्रणाली"}
      </h4>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <div className="menus">
        {upperData && <SidebarButtonRenderer data={upperData} user={user} />}
        {checkPermission(user?.role, "view:templates") && (
          <PaperTemplateRenderer />
        )}
        {lowerData && <SidebarButtonRenderer data={lowerData} user={user} />}
      </div>
    </div>
  );
};

export default Sidebar;
