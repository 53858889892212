import React, { Fragment } from "react";
import { connect } from "react-redux";
import { PALIKADATA } from "../_datas";
import { setSelectValue } from "../_redux/slices/formTemplates";

function SelectProvince({ elementID, setSelectValue }) {
  return (
    <Fragment>
      <input
        name={elementID}
        id={elementID}
        type="text"
        list="province"
        onChange={(e) =>
          setSelectValue({ key: elementID, value: e.target.value })
        }
      />
      <datalist id="province">
        <option value="">कृपया प्रदेश छान्नुहोस</option>
        {Object.keys(PALIKADATA).map((province) => (
          <option key={province} value={province}>{province}</option>
        ))}
      </datalist>
    </Fragment>
  );
}

export default connect(null, { setSelectValue })(SelectProvince);
